import { BrandingOrganisation } from 'branding/Branding';

import { env, EnvType } from './env';

// eslint-disable-next-line
import packageJson from '../../package.json';

export interface AppConfig {
  environment: 'local' | 'development' | 'production';
  version: string;
  pageTitle: string;
  brandOrganisation: BrandingOrganisation;
  apiUrl: string;
  enrolmentsUrl: string;
  marketplaceUrl: string;
  agenciesUrl: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0CallbackUrl: string;
  auth0Audience: string;
  auth0Organisation?: string;
  sentryDsn?: string;
  educatiusResourcesBasePath?: string;
  logRocketId?: string;
}

const throwIfUndefined = (envVar: keyof EnvType): string => {
  if (env[envVar] === undefined) {
    throw new Error(`${String(envVar)} is not defined, cannot load application config`);
  }

  return env[envVar]!;
};

let config: AppConfig;

export const getConfig = (): AppConfig => {
  if (config) return config;
  let deploymentTarget = throwIfUndefined('REACT_APP_ENV');
  if (!['local', 'development', 'production'].includes('deploymentTarget')) {
    deploymentTarget = 'production';
  }
  const auth0Organisation = throwIfUndefined('REACT_APP_AUTH0_ORGANISATION');

  let educatiusResourcesBasePath = undefined;
  const brandOrganisation = throwIfUndefined('REACT_APP_BRAND_ORGANISATION') as BrandingOrganisation;
  if (brandOrganisation === 'educatius') {
    educatiusResourcesBasePath = throwIfUndefined('REACT_APP_EDUCATIUS_RESOURCES_BASEPATH');
  }

  config = {
    environment: deploymentTarget as 'local' | 'development' | 'production',
    version: packageJson.version.trim(),
    pageTitle: throwIfUndefined('REACT_APP_PAGE_TITLE'),
    brandOrganisation,
    apiUrl: throwIfUndefined('REACT_APP_BACKEND_HOST'),
    enrolmentsUrl: throwIfUndefined('REACT_APP_ENROLLER'),
    marketplaceUrl: throwIfUndefined('REACT_APP_MARKETPLACE'),
    agenciesUrl: throwIfUndefined('REACT_APP_AGENCIES'),
    auth0Domain: throwIfUndefined('REACT_APP_AUTH0_DOMAIN'),
    auth0ClientId: throwIfUndefined('REACT_APP_AUTH0_CLIENT_ID'),
    auth0CallbackUrl: throwIfUndefined('REACT_APP_AUTH0_CALLBACK_URL'),
    auth0Audience: throwIfUndefined('REACT_APP_AUTH0_AUDIENCE'),
    auth0Organisation: auth0Organisation === 'default' ? undefined : auth0Organisation,
    sentryDsn: env['REACT_APP_SENTRY_DSN'],
    educatiusResourcesBasePath,
    logRocketId: env['REACT_APP_LOG_ROCKET_ID'],
  };
  return config;
};
