import { forwardRef } from 'react';
import { createTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { branding } from 'branding';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

/**
 * @see https://mui.com/material-ui/integrations/routing/#global-theme-link
 */
const LinkBehavior = forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material-UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

/**
 * If adding new mixins or variables to this file remember to update theme.d.ts definitions too
 */
const inputBorderColour = 'rgba(0, 0, 0, 0.23)';

// theme is split into two parts to allow second part to reference variables declared in first
const baseTheme = createTheme({
  typography: {
    // text size reset
    fontSize: 17,
    fontFamily: branding.fontFamily,
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '4.8rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #D0D5DD',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #D0D5DD',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          ':hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3EAD47',
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[600],
      light: grey[300],
      dark: grey[700],
    },
    text: {
      primary: grey[800],
    },
    background: {
      default: grey[50],
    },
    success: {
      main: green[500],
      light: green[50],
      dark: green[700],
    },
    warning: {
      main: orange[500],
      light: orange[50],
      dark: orange[700],
    },
    error: {
      main: red[500],
      light: red[50],
      dark: red[700],
    },
    info: {
      main: grey[600],
      light: grey[200],
      dark: grey[700],
    },
    shadows: {
      light: '#10182808',
      dark: '#10182814',
    },
    ...branding.paletteOptions,
  },
});

const borderRadius = baseTheme.spacing(1);

const getAlertStyles = (severity: 'info' | 'error' | 'success' | 'warning') => ({
  backgroundColor: baseTheme.palette[severity].light,
  borderColor: baseTheme.palette[severity].main,
  color: baseTheme.palette[severity].dark,
});

const getButtonStyles = (color: 'primary' | 'secondary', variant: 'text' | 'outlined' | 'contained') => {
  switch (variant) {
    case 'outlined':
      return {
        borderColor: baseTheme.palette[color].light,
        color: baseTheme.palette[color].main,
      };
    default:
      return {};
  }
};

export const theme = createTheme(baseTheme, {
  mixins: {
    titleGradient: {
      background: `-webkit-linear-gradient(left, ${baseTheme.palette.titleGradientColor1}, ${baseTheme.palette.titleGradientColor2})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxHeight: '75vh',
      display: 'flex',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: '10px',
      border: 'none',
      outline: 'none',
      p: 3,
    },
    secondaryText: {
      color: baseTheme.palette.text.primary,
      fontWeight: 'light',
      fontSize: 14,
    },
    activeButton: {
      border: `2px solid ${baseTheme.palette.primary.main} !important`,
      boxShadow: `0px 0px 0px 4px ${baseTheme.palette.primary.light}`,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          fontFamily: baseTheme.typography.fontFamily,
          borderRadius,
          boxShadow: `0px 8px 8px -4px ${baseTheme.palette.shadows.light}, 0px 20px 24px -4px ${baseTheme.palette.shadows.dark}`,
          border: `1px solid ${grey[400]}`,
          marginTop: baseTheme.spacing(0.5),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: '6px',
          border: `1px solid ${inputBorderColour}`,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: `${baseTheme.palette.error.main}` },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&, input, fieldset': {
            borderRadius,
          },
          '&:not(:is(:focus, .Mui-disabled)):hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.primary.light,
            borderWidth: '2px',
            textDecoration: 'none',
          },
          '&:is(.Mui-focused, :focus) fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: `${baseTheme.palette.primary.main} !important`,
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.primary.light}7A`,
          },
          '&:is(.Mui-disabled)': {
            backgroundColor: `${baseTheme.palette.grey[100]}`,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: `0px 1px 2px 0px ${baseTheme.palette.grey[300]}, 0px 1px 3px 0px ${baseTheme.palette.grey[200]}`,
          borderRadius,
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[100],
          fontWeight: 500,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderRadius,
          '&.MuiAlert-outlinedInfo': getAlertStyles('info'),
          '&.MuiAlert-outlinedError': getAlertStyles('error'),
          '&.MuiAlert-outlinedSuccess': getAlertStyles('success'),
          '&.MuiAlert-outlinedWarning': getAlertStyles('warning'),
          // Used to style alert content when it's an HTML string
          '& .MuiAlert-message *': {
            color: 'inherit',
            margin: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(1.5)}`,
          borderRadius,
          '&.MuiButton-outlined:not(.Mui-disabled)': {
            '&.MuiButton-colorSecondary': getButtonStyles('secondary', 'outlined'),
            '&.MuiButton-colorPrimary': getButtonStyles('primary', 'outlined'),
          },
          // Used for buttons that render as a link
          '&.MuiButton-containedPrimary[href]:hover': { color: baseTheme.palette.primary.contrastText },
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: 'middle',
      },
    },
  },
});
