import { useAuth0 } from '@auth0/auth0-react';
import { MouseEventHandler, useState } from 'react';
import { getConfig } from 'utils/config';
import { isMenuItemActive } from 'utils/isMenuItemActive';
import { AuthUser } from 'types/user';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material';
import { greenheartStaffRoles, UserRole } from 'app/auth/Roles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';

import goLogo from './go-logo.svg';

// TODO: permissions context
const hasAllowedRole = (user: AuthUser | undefined, allowedRoles: UserRole[]) => allowedRoles.some((allowedRole) => user?.userData.roles.includes(allowedRole));

const GreenheartMenuItem = ({ url, label }: { url: string; label: string }) => {
  const theme = useTheme();
  const menuLinkSx = (active: boolean) => ({
    px: 2,
    color: theme.palette.primary.main,
    height: '80px',
    lineHeight: '80px',
    borderBottomWidth: '5px',
    borderBottomStyle: 'solid',
    borderBottomColor: active ? theme.palette.primary.light : 'transparent',
  });

  return (
    <Link href={url} sx={menuLinkSx(isMenuItemActive(url))}>
      {label}
    </Link>
  );
};

const HostOrgPartnerMenu = () => {
  const { enrolmentsUrl } = getConfig();
  const { user } = useAuth0<AuthUser>();
  const mainSchoolSlug = user?.userData.schoolSlugs?.[0];

  const showPartnerLinks = hasAllowedRole(user, [UserRole.PartnerStaff, UserRole.PartnerAdmin]);

  return (
    <>
      <GreenheartMenuItem url={`${enrolmentsUrl}/${mainSchoolSlug}/positions`} label="Positions" />
      {showPartnerLinks && (
        <>
          <GreenheartMenuItem url={`${enrolmentsUrl}/${mainSchoolSlug}/participants`} label="Participants" />
          <GreenheartMenuItem url={`${enrolmentsUrl}/${mainSchoolSlug}/host-orgs`} label="Host Orgs" />
        </>
      )}
    </>
  );
};

const ParticipantMenu = () => {
  const { enrolmentsUrl } = getConfig();
  const { user } = useAuth0<AuthUser>();
  const mainSchoolSlug = user?.userData.schoolSlugs?.[0];
  const url = mainSchoolSlug ? `${enrolmentsUrl}/${mainSchoolSlug}/pipeline` : '/';
  return <GreenheartMenuItem url={url} label="Placement" />;
};

const GreenheartStaffMenu = () => {
  const { enrolmentsUrl } = getConfig();
  const { user } = useAuth0<AuthUser>();
  const mainSchoolSlug = user?.userData.schoolSlugs?.[0];

  return (
    <>
      <GreenheartMenuItem url={`${enrolmentsUrl}/${mainSchoolSlug}/positions`} label="Positions" />
      <GreenheartMenuItem url={`${enrolmentsUrl}/${mainSchoolSlug}/participants`} label="Participants" />
    </>
  );
};

export const GreenheartHeader = () => {
  const { user } = useAuth0<AuthUser>();
  const theme = useTheme();
  const bgcolor = theme.palette.background.paper;
  const color = theme.palette.getContrastText(bgcolor);

  const showParticipantMenu = hasAllowedRole(user, [UserRole.Applicant]);
  const showGhStaffMenu = hasAllowedRole(user, greenheartStaffRoles);
  const showHostOrgPartnerMenu = hasAllowedRole(user, [
    UserRole.HostOrganizationStaff,
    UserRole.HostOrganizationAdmin,
    UserRole.PartnerStaff,
    UserRole.PartnerAdmin,
  ]);

  return (
    <AppBar sx={{ color, bgcolor }}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <Avatar src={goLogo} alt="Brand logo" variant="square" sx={{ width: '64.5px', height: '50px' }} />
          <Box px={3}>
            <Divider orientation="vertical" variant="middle" sx={{ height: '32px', borderWidth: '1px' }} />
          </Box>
          <Box flexGrow="1" />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} mr={1}>
            {showHostOrgPartnerMenu && <HostOrgPartnerMenu />}
            {showParticipantMenu && <ParticipantMenu />}
            {showGhStaffMenu && <GreenheartStaffMenu />}
            <UserMenu />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

const UserMenu = () => {
  const { user, logout } = useAuth0<AuthUser>();
  const { agenciesUrl, enrolmentsUrl } = getConfig();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mainSchoolSlug = user?.userData.schoolSlugs[0] ?? null;
  const profilePageUrl =
    user?.userData.roles.includes(UserRole.Agent) || mainSchoolSlug === null ? `${enrolmentsUrl}/profile` : `${enrolmentsUrl}/${mainSchoolSlug}/profile`;
  const renderUserDetails = Boolean(user);
  const onClose: MouseEventHandler<HTMLElement> = () => setAnchorEl(null);

  return (
    <div>
      <Tooltip title="Profile Menu">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0, height: 36 }}>
          <AccountCircleOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <List>
          {renderUserDetails && (
            <Link href={profilePageUrl} target="_blank" underline="none">
              <ListItem>
                <ListItemAvatar>
                  <AccountCircleOutlinedIcon />
                </ListItemAvatar>
                <ListItemText primary={`${user!.userData.firstName} ${user!.userData.lastName}`} secondary={user!.email} />
              </ListItem>
            </Link>
          )}
          <Divider />
          <ListItem href={agenciesUrl} {...{ component: Link }} onClick={onClose}>
            <ListItemIcon>
              <KeyOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Manage User Access</ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="" />
            <Button
              variant="outlined"
              onClick={() => {
                setAnchorEl(null);
                logout({ logoutParams: { returnTo: agenciesUrl } });
              }}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
