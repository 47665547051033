import { AppBar, Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const GreenheartFooter = () => {
  const theme = useTheme();

  const linkSx = {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  };
  const year = new Date().getFullYear();

  return (
    <AppBar
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor: '#fff',
        width: '100%',
        borderTop: `solid 1px ${grey[200]}`,
        top: 'auto',
        bottom: 0,
        zIndex: 1250,
      }}
      component="footer"
      position="relative"
      elevation={0}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ ml: 2, color: grey[800], fontWeight: 500 }}>
          &copy;{year} Greenheart Org. All rights reserved
        </Typography>
      </Box>

      <Stack>
        <Box sx={{ display: 'flex', mr: 7 }}>
          <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={linkSx}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Help Centre
            </Typography>
          </Link>
          <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx, ml: 3 }}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Terms of Use
            </Typography>
          </Link>
          <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx, ml: 3 }}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Privacy Policy
            </Typography>
          </Link>
        </Box>
      </Stack>
    </AppBar>
  );
};
