import { useAuth0 } from '@auth0/auth0-react';
import { deepMerge } from 'utils/deepMerge';
import { getConfig } from 'utils/config';
import { getErrorMessage } from 'utils/errors';
import { loginConfig } from 'app/auth/login-config';
import { useEffect, useMemo, useRef } from 'react';

export interface UsePutOptions {
  useApiUrl?: boolean;
  isMultiPartForm?: boolean;
  headers?: HeadersInit;
}

// Static objects for default parameters to prevent re-renders
const emptyObject = {};
const defaultOptions = {
  useApiUrl: true,
  isMultiPartForm: false,
  headers: emptyObject,
};

export const useStatelessPut = <T>(url: string, options: UsePutOptions = defaultOptions) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const ref = useRef({ options });

  useEffect(() => {
    ref.current = { options };
  });

  const put = useMemo(() => {
    const { useApiUrl, isMultiPartForm, headers } = deepMerge(defaultOptions, ref.current.options);
    const { apiUrl } = getConfig();
    const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;

    return async (data?: unknown, params: URLSearchParams = new URLSearchParams()): Promise<T> => {
      try {
        const token = await getAccessTokenSilently();
        const config: RequestInit = deepMerge(
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: data instanceof FormData ? data : JSON.stringify(data || {}),
          },
          { headers },
        );
        // this is so the browser can properly set the header with boundary length
        if (isMultiPartForm) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          delete config.headers['Content-Type'];
        }
        const compiledUrl = params.toString() ? `${fetchUrl}?${params.toString()}` : fetchUrl;

        return fetch(compiledUrl, config).then(async (resp) => {
          let body;

          if (resp.headers.get('content-type') === 'application/json') {
            body = await resp.json();
          } else {
            body = await resp.text();
          }

          if (resp.status !== 200 && resp.status !== 201 && resp.status !== 204) {
            throw new Error(getErrorMessage(body));
          }

          return body;
        });
      } catch {
        await loginWithRedirect(loginConfig(window.location.pathname));
        throw new Error('Not logged in. Redirecting...');
      }
    };
  }, [getAccessTokenSilently, loginWithRedirect, url]);

  return put;
};
