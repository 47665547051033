import { useAuth0 } from '@auth0/auth0-react';
import { deepMerge } from 'utils/deepMerge';
import { getConfig } from 'utils/config';
import { getErrorMessage } from 'utils/errors';
import { useMemo, useRef, useEffect } from 'react';
import { loginConfig } from 'app/auth/login-config';

export interface UseGetOptions {
  useApiUrl?: boolean;
  skipAuth?: boolean;
  headers?: HeadersInit;
  isBlob?: boolean;
}

// Static objects for default parameters to prevent re-renders
const emptyObject = {};
const defaultOptions = {
  useApiUrl: true,
  skipAuth: false,
  headers: emptyObject,
  isBlob: false,
};

export const useStatelessGet = <T>(url: string, options: UseGetOptions = defaultOptions) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const ref = useRef({ options });

  useEffect(() => {
    ref.current = { options };
  });

  const get = useMemo(() => {
    const { useApiUrl, skipAuth, headers, isBlob } = deepMerge(defaultOptions, ref.current.options);
    const { apiUrl } = getConfig();
    const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;
    const initialHeaders: HeadersInit = {
      Accept: 'application/json',
    };

    return async (params: URLSearchParams = new URLSearchParams()): Promise<T> => {
      try {
        if (skipAuth !== true) {
          const token = await getAccessTokenSilently();
          initialHeaders.Authorization = `Bearer ${token}`;
        }
      } catch {
        await loginWithRedirect(loginConfig(window.location.pathname));
        throw new Error('Not logged in. Redirecting...');
      }

      const config = deepMerge(
        {
          headers: initialHeaders,
          method: 'GET',
        },
        { headers },
      );
      const compiledUrl = params.toString() ? `${fetchUrl}?${params.toString()}` : fetchUrl;

      return fetch(compiledUrl, config).then(async (resp) => {
        let body;

        if (resp.headers.get('content-type')?.includes('application/json')) {
          body = await resp.json();
        } else if (isBlob) {
          body = await resp.blob();
        } else {
          body = await resp.text();
        }

        if (resp.status !== 200 && resp.status !== 204) {
          throw new Error(getErrorMessage(body), { cause: resp });
        }

        return body;
      });
    };
  }, [getAccessTokenSilently, loginWithRedirect, url]);

  return get;
};
