import { Branding } from 'branding/Branding';

import { GreenheartHeader } from './GreenheartHeader';
import { GreenheartFooter } from './GreenheartFooter';

const primaryMain = '#577b27';

export const greenheartBranding: Branding = {
  header: <GreenheartHeader />,
  headerHeight: 10,
  footer: <GreenheartFooter />,
  fontFamily: 'Lato, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#74a535',
      dark: '#486620',
      contrastText: '#ffffff',
    },
    titleGradientColor1: primaryMain,
    titleGradientColor2: primaryMain,
  },
};
