import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { usePost } from 'hooks/usePost';
import { Controller, useForm } from 'react-hook-form';
import { useNotificationMessages } from 'utils/notifications';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/responseEnvelope';
import { usePut } from 'hooks/usePut';
import { UserRole } from 'app/auth/Roles';
import { GradientIcon } from 'components/GradientIcon';

import { AgencyStaff } from './types/AgencyStaff';

interface AddStaffForm {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface FormRoles {
  label: string;
  description: string;
  value: string;
}

interface AddStaffDialogProps {
  active: boolean;
  onToggle: () => void;
  staffId?: string;
  agencyId: string;
  staff?: AgencyStaff;
  userRoles: UserRole[];
}

export const StaffDialog = ({ active, onToggle, staff, agencyId, userRoles }: AddStaffDialogProps) => {
  const theme = useTheme();
  const [postLoading, postUser] = usePost<ResponseEnvelope<any>>(`/partner-api/agencies/${agencyId}/users`);
  const [putLoading, putUser] = usePut<ResponseEnvelope<any>>(`/partner-api/agencies/${agencyId}/users/${staff?.id}`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const formValues = {
    firstName: staff?.first_name || '',
    lastName: staff?.last_name || '',
    email: staff?.email || '',
    role: staff?.roles[0],
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddStaffForm>({
    mode: 'onTouched',
    resetOptions: {
      keepDirtyValues: false, // user-interacted input will be retained
    },
    defaultValues: formValues,
  });

  const roleOptions: FormRoles[] = userRoles.includes(UserRole.PartnerAdmin)
    ? [
        {
          label: 'Partner Admin',
          description: '',
          value: 'partner-admin',
        },
        {
          label: 'Partner Staff',
          description: '',
          value: 'partner-staff',
        },
      ]
    : userRoles.includes(UserRole.HostOrganizationAdmin)
    ? [
        {
          label: 'Host Org Admin',
          description: '',
          value: 'host-org-admin',
        },
        {
          label: 'Host Org Staff',
          description: '',
          value: 'host-org-staff',
        },
      ]
    : [];

  const closeAndResetForm = () => {
    reset();
    onToggle();
  };

  const onSubmit = handleSubmit(async (data: AddStaffForm) => {
    try {
      const response = await (staff ? putUser(data) : postUser(data));
      showSuccessMessage(response.message);
      closeAndResetForm();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  });

  return (
    <>
      {active && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Dialog open={active} aria-labelledby="add-staff-dialog-title" aria-describedby="add-staff-dialog-description" fullWidth maxWidth="sm">
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={2}>
            <GradientIcon color="primary" IconComponent={PersonAddIcon} />
            <Typography id="add-staff-dialog-title" variant="h6" component="h2" my={2}>
              {staff ? 'Edit' : 'Add'} staff
            </Typography>
            <Box flex={1} />
            <Tooltip title="Discard">
              <span>
                <IconButton aria-label="discard" onClick={() => closeAndResetForm()}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box minHeight={0} flex={1}>
            <Stack spacing={1}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'First name is required',
                  maxLength: {
                    value: 200,
                    message: 'Name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth required>
                    <FormLabel id="add-staff-first-name-label">First name</FormLabel>
                    <TextField
                      required
                      fullWidth
                      aria-labelledby="add-staff-first-name-label"
                      value={value}
                      size="small"
                      onChange={onChange}
                      error={Boolean(errors.firstName)}
                    />
                    {errors.firstName ? <Typography color="error">{errors.firstName.message}</Typography> : <br />}
                  </FormControl>
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Last name is required',
                  maxLength: {
                    value: 200,
                    message: 'Name is too long',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth required>
                    <FormLabel id="add-staff-last-name-label">Last name</FormLabel>
                    <TextField
                      required
                      fullWidth
                      aria-labelledby="add-staff-last-name-label"
                      value={value}
                      size="small"
                      onChange={onChange}
                      error={Boolean(errors.lastName)}
                    />
                    {errors.lastName ? <Typography color="error">{errors.lastName.message}</Typography> : <br />}
                  </FormControl>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Invalid email',
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth required>
                    <FormLabel id="add-staff-email-label">Email</FormLabel>
                    <TextField
                      required
                      fullWidth
                      aria-labelledby="add-staff-email-label"
                      value={value}
                      size="small"
                      onChange={onChange}
                      error={Boolean(errors.lastName)}
                    />
                    {errors.email ? <Typography color="error">{errors.email.message}</Typography> : <br />}
                  </FormControl>
                )}
              />
              <Controller
                name="role"
                control={control}
                rules={{
                  required: 'Role is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth size="small" required>
                    <FormLabel id="add-staff-role-label">Role</FormLabel>
                    <Select
                      variant="outlined"
                      labelId="add-staff-role-select-label"
                      id="add-staff-role-select"
                      value={value}
                      onChange={(event) => onChange(event.target.value as string)}
                    >
                      {roleOptions.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          <Typography sx={{ mr: 1 }}>{role.label}</Typography>
                          <Typography sx={{ ...theme.mixins.secondaryText }}>{role.description}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role ? <Typography color="error">{errors.role.message}</Typography> : <br />}
                  </FormControl>
                )}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => closeAndResetForm()} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={Boolean(Object.keys(errors).length !== 0)}
            onClick={onSubmit}
            loading={postLoading || putLoading}
            color="primary"
          >
            {staff ? 'Edit' : 'Add'} Staff
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
